body {
    background-color: lightsteelblue;
}

.page {
    color: steelblue;
    display: flex;
    font-style: italic;
    justify-content: center;
}

.company {
    position: absolute;
    text-align: center;
    top: 20%;
}

.companyDescription {
    font-size: 0.7em;
}

.companyName {
    font-size: 2em;
}

.contact {
    bottom: 5%;
    font-size: 0.7em;
    position: absolute;
}

.flexColumn {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.more {
    color: steelblue;
    font-style: italic;
    position: absolute;
    text-align: center;
    top: 60%;
}